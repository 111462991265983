import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Besichtigunstermin = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="10 Tipps für deinen Besichtigungstermin" showCalc={false} />
            <Article>
                <p>
                    Die{" "}
                    <Link
                        to="/artikel/haushaltsrechnung-schritt-fuer-schritt/"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Haushaltsrechnung
                    </Link>{" "}
                    ist gemacht, das verfügbare{" "}
                    <Link to="/artikel/finanzierung-ohne-eigenkapital/" target="_blank" rel="noreferrer noopener">
                        Eigenkapital
                    </Link>{" "}
                    hast du fest im Blick, die Finanzierung zumindest mittels{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    geplant und auf das eine odere andere Objekt hast du auch schon ein Auge geworfen? Dann ist der
                    nächste Schritt der Besichtigungstermin. Worauf es dabei ankommt, haben wir im Folgenden
                    zusammengefasst
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>1. Bereite dich vor!</h2>
                <p>
                    Im Idealfall sendet dir die Makler:in vorab ein Exposé zu, das die wichtigsten Daten zu der
                    jeweiligen Immobilie enthält. Lies dir das Dokument aufmerksam durch und notiere sämtliche Fragen,
                    die dabei offen bleiben in Form einer Checkliste, die du beim Gespräch mit der Makler:in Punkt für
                    Punkt abarbeiten kannst. So vergisst du bestimmt nichts Wichtiges!
                </p>
                <hr />

                <h2>2. Sieh dir die Umgebung an!</h2>
                <p>
                    Plane vor oder nach dem Besichtigungstermin ausreichend Zeit ein, um die Umgebung deines zukünftigen
                    Zuhauses zu erkunden. Dabei kannst du sicherstellen, dass die Wohngegend tatsächlich deinen
                    Vorstellungen entspricht. Ist die Lage ruhig genug? Gibt es genügend Einkaufsmöglichkeiten? Wie
                    sieht es mit Ärzt:innen und der Verkehrsanbindung aus? All diese Fragen kannst du beim Flanieren
                    durch die Straßen deines potentiellen Wohnviertels beantworten.
                </p>
                <hr />

                <h2>3. Schau genau hin!</h2>
                <p>
                    Sieh dir die Wohnung in Ruhe und im Detail an und notiere alle Mängel und Schäden, die dir dabei
                    auffallen. Sprich mit der Makler:in aber erst über sie, wenn du den Kaufvertrag unterzeichnest bzw.
                    wenn die Übergabe erfolgt. So werden sie im Übergabeprotokoll vermerkt, womit du dafür sorgst, dass
                    du nicht für Schäden haftest, die du gar nicht verurarsacht hast.
                </p>
                <hr />

                <h2>4. Mach Bilder!</h2>
                <p>
                    Sofern Makler:in und/oder Verkäufer:in ihr Einverständnis geben, kannst du die Immobilie beim
                    Besichtigungstermin fotografieren. Gerade wenn du über einen längeren Zeitraum mehrere Objekte
                    besichtigst, können Fotos bei der Entscheidung hilfreich sein.
                </p>
                <hr />

                <h2>5. Nimm Maß!</h2>
                <p>
                    Wir empfehlen dir, zu deinem Besichtigungstermin in jedem Fall ein Maßband mitzunehmen. So kannst du
                    nicht nur die Angaben in der Anzeige überprüfen, sondern dir auch gleich ansehen, wo sich deine
                    Möbel in der neuen Wohnung am besten machen.
                </p>
                <hr />

                <h2>6. Achte auf die Tageszeit!</h2>
                <p>
                    Wir raten dir, eine Besichtigung bei Tageslicht vorzunehmen. Damit kannst du einerseits die
                    Lichtsituation in den Räumlichkeiten beurteilen. Andererseits lassen sich so etwaige Schäden und
                    Mängel besser erkennen. Im Falle eines zweiten Besichtigungstermin kannst du dir noch ansehen, wie
                    es abends um die Lärmsituation bestellt ist.
                </p>
                <hr />

                <h2>7. Wähle den richtigen Wochentag aus!</h2>
                <p>
                    Auch der Wochentag, an dem der Besichtigungstermin stattfindet, ist entscheidend. Wähle am besten
                    einen Werktag aus, sodass du eine genaue Vorstellung vom Verkehrslärm sowie der Parksituation in der
                    neuen Umgebung bekommst. Im Falle einer Wohnung gilt es auch andere Lärmquellen zu identifizieren
                    wie Nachbar:innen, die im Homeoffice tätig, mit der Betreuung eines Kindes beschäftigt oder generell
                    nicht erwerbstätig sind.
                </p>
                <hr />

                <h2>8. Nimm jemanden mit!</h2>
                <p>
                    Besonders empfehlenswert ist es zudem, eine weitere Person zu deinem Besichtigungstermin
                    mitzunehmen. So wird dein Blick auf die Immobilie bestätigt oder durch einen weiteren Eindruck
                    korrigiert. Vier Augen sehen bekanntlich mehr mehr als zwei - das gilt auch für Schäden an der
                    Wohnung bzw. am Haus. Bist du unsicher, was die{" "}
                    <Link to="/artikel/immobilienbewertung/" target="_blank" rel="noreferrer noopener">
                        Bewertung
                    </Link>{" "}
                    der Immobilie betrifft, dann kannst du auch einen Sachverständigen zum Besichtigungstermin
                    mitbringen.
                </p>
                <hr />

                <h2>9. Mach einen guten Eindruck!</h2>
                <p>
                    Bei einem Besichtigungstermin geht es nicht nur um den Eindruck, den die Immobilie auf dich macht,
                    sondern auch um um den Eindruck, den du bei der Makler:in bzw. der Verkäufer:in hinterlässt. Auch du
                    bist sozusagen Besichtigungsobjekt. Achte also darauf, pünktlich zu sein, und überzeuge die
                    Makler:in durch dein freundliches Auftreten davon, dass du die:der richtige Käufer:in bist!
                </p>
                <hr />

                <h2>10. Denk an essenzielle Unterlagen!</h2>
                <p>
                    Die meisten Makler:innen senden dir vorab ein Formular zur Selbstauskunft zu, in das du die
                    wichtigsten Daten zu deiner Person einträgst, um es beim Besichtigungstermin vorlegen zu können.
                    Ratsam ist es zudem, einen positiven Bonitätsnachweis oder – sofern bereits vorhanden – eine
                    Finanzierungsbestätigung mitzubringen.
                </p>
            </Article>

            <BreadcrumbList page={"besichtigunstermin"}></BreadcrumbList>
            <ArticleStructuredData page={"besichtigunstermin"} heading={"10 Tipps für deinen Besichtigungstermin"} />
        </Layout>
    );
};

export default Besichtigunstermin;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.besichtigunstermin", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
